export function serializeQuery(obj: any): string {
    if (obj == null) {
        return "";
    }
    let qs = serializeQueryRecursive(obj, undefined, undefined);
    return (qs.substring(1, qs.length));
}

function serializeQueryRecursive(obj: any, path: string = "", parentIsArray: boolean = false): string {
    let qs = "";
    for (var prop in obj) {
        if (typeof obj[prop] == "function") {
            continue;
        }
        let val = obj[prop];
        if (typeof val === 'undefined') {
            val = "";
        }
        let pathAndProp = path;
        if (parentIsArray) {
            pathAndProp += "[";
        }
        pathAndProp += prop;
        if (parentIsArray) {
            pathAndProp += "]";
        }
        if (typeof val === 'object') {
            if (Array.isArray(val)) {
                qs += serializeQueryRecursive(val, pathAndProp, true);
            } else {
                qs += serializeQueryRecursive(val, pathAndProp + ".");
            }
        } else {
            qs += "&" + encodeURIComponent(pathAndProp) + "=" + encodeURIComponent(val);
        }
    }

    return qs;
}

export function deserializeQuery<T>(qs: string, hoistSinglePropObj: boolean = true, reviver?: (obj: any) => T): T {
    if (qs.substring(0, 1) == "?") {
        qs = qs.substring(1, qs.length);
    }
    let segments = qs.split("&");
    let root = {};
    for (let segment of segments) {
        let skip = false;
        let keyAndValue = segment.split("=");
        let value = decodeURIComponent(keyAndValue[1]);
        let potentialNodes = decodeURIComponent(keyAndValue[0]).split(".");
        let path: {} | null = null;
        for (let i = 0; i < potentialNodes.length; i++) {
            let firstBracket = potentialNodes[i].indexOf("[");
            let lastBracket = potentialNodes[i].indexOf("]");
            if (firstBracket >= 0 && firstBracket >= lastBracket) {
                skip = true; // go no further with this node list since it is malformed
                break;
            }
            let node = potentialNodes[i].substring(0, (firstBracket < 0) ? potentialNodes[i].length : firstBracket);
            if (path == null) {
                if (typeof root[node] == "undefined") {
                    root[node] = (firstBracket >= 0) ? [] : {};
                }
                path = root;
            }
            if (firstBracket >= 0) { // We have an array indexer
                let index = parseInt(potentialNodes[i].substring(firstBracket + 1, lastBracket));
                if (index == null) {
                    index = 0;
                }
                if (typeof path[node] == "undefined") {
                    path[node] = [];
                }
                if (typeof path[node] != "object" || !Array.isArray(path[node])) {
                    skip = true; // go no further because we have already defined this node as something other than an array (malformed)
                    break;
                }
                for (let previousIndex = 0; previousIndex < index; previousIndex++) {
                    if (typeof path[node][previousIndex] == "undefined") {
                        path[node][previousIndex] = null;
                    }
                }
                if (i == potentialNodes.length - 1) { // Last in the path
                    path[node][index] = value;
                } else if (typeof path[node][index] == "undefined") {
                    path[node][index] = {};
                }
                path = path[node][index]; // One level deeper
            } else { // Normal path
                if (i == potentialNodes.length - 1) { // Last in the path
                    path[node] = value;
                } else if (typeof path[node] == "undefined") {
                    path[node] = {};
                }
                path = path[node]; // One level deeper
            }
        }
        if (skip) {
            continue;
        }
    }
    if (hoistSinglePropObj) {
        let keys = Object.keys(root);
        if (keys.length == 1) {
            root = root[keys[0]];
        }
    }
    if (reviver != null) {
        return reviver(root);
    }
    return root as T;
}


