import { serializeQuery } from '../utility/mvc-query-string';
import axios from 'axios';

const api = axios.create({
    paramsSerializer: serializeQuery
});

export interface ISectionViewModel {
    ContentContainerId: number;
    ContentContainerName: string;
    ContentViewModel: any;
    DoNotWrapInDiv: boolean;
    Id: number;
    IsEditable: boolean;
    IsInCmsMode: boolean;
    IsPreviewing: boolean;
    OverrideTemplateDisplayName: string;
    RouteValue: string;
}

class CMSAPI {
    private readonly _cache: { [key: string]: Promise<any> } = {};

    getContent(contentContainerId: number, params?: any): Promise<string> {
        let cached = this._cache[contentContainerId] || null;
        if (cached) {
            return cached.then(response => response);
        }
        return this._cache[contentContainerId] = api.get(
            `/cms/public/section/${contentContainerId}/`,
            { params }
        ).then(response => response.data);
    }

    getContentContainerIdByCategory(categoryId: number): Promise<any> {
        const cacheKey = `catId-${categoryId}`;
        let cached = this._cache[cacheKey] || null;
        if (cached) {
            return cached.then(response => response);
        }

        return this._cache[cacheKey] = api.post(
            `/homes/getcontentcontaineridbynavcategoryjson/`,
            { categoryId } as any
        ).then(response => response.data);
    }

    getSectionJson(
        contentContainerId: number,
        params?: any
    ): Promise<any[]> {
        const key = `${contentContainerId}_json`;
        let cached = this._cache[key] || null;
        if (cached) {
            return cached.then(response => response);
        }

        params = params || {};
        params = Object.assign({}, { api_fmt: "json" }, params);

        return this._cache[key] = api.get(
            `/cms/public/section/${contentContainerId}/`,
            { params }
        ).then(response => response.data);
    }

    getSectionsJson(
        contentTypeRouteFragment: string,
        recordsPerPage: number = 5,
        page: number = 1
    ) {
        const key = `${contentTypeRouteFragment}_${page}_${recordsPerPage}`;
        let cached = this._cache[key] || null;
        if (cached) {
            return cached.then(response => response);
        }

        const params = { recordsPerPage, page, api_fmt: "json" };
        return this._cache[key] = api.get(
            `/cms/${contentTypeRouteFragment}/public/sections/`,
            { params }
        ).then(r => r.data.Sections);
    }
}

export default new CMSAPI();